import { failingFetcher } from '@lib/http/fetch';
import * as Sentry from '@sentry/nextjs';
import { CoveoSearchHub } from './coveoConstants';

const SEARCH_TOKEN_LOCAL_STORAGE_KEY = (searchHub: CoveoSearchHub) =>
  `coveoSearchToken-${searchHub}`;

/** Calls the server to request a new Coveo search token from the Coveo API, and saves the new token to localStorage */
export const getNewSearchToken = async (
  searchHub: CoveoSearchHub
): Promise<string> => {
  try {
    const searchToken = await failingFetcher(
      `/api/coveo/search-token?searchHub=${searchHub}`,
      {}
    );

    if (searchToken && typeof searchToken === 'string') {
      setSavedSearchToken(searchHub, JSON.stringify(searchToken));
      return searchToken;
    } else {
      Sentry.captureException(
        new Error(
          `Error in getNewSearchToken, invalid searchToken: ${searchToken}`
        )
      );
    }
  } catch (error) {
    Sentry.captureException(error);
  }

  return '';
};

/** Grabs the coveo search token from localStorage */
export const getSavedSearchToken = (
  searchHub: CoveoSearchHub
): string | null => {
  return localStorage?.getItem(SEARCH_TOKEN_LOCAL_STORAGE_KEY(searchHub));
};

/** Saves the coveo search token to localStorage */
export const setSavedSearchToken = (
  searchHub: CoveoSearchHub,
  newToken: string
): void => {
  localStorage?.setItem(SEARCH_TOKEN_LOCAL_STORAGE_KEY(searchHub), newToken);
};

/** Removes the coveo search token from localStorage */
export const resetSearchTokens = () => {
  localStorage?.removeItem(
    SEARCH_TOKEN_LOCAL_STORAGE_KEY(CoveoSearchHub.SEARCH)
  );
  localStorage?.removeItem(
    SEARCH_TOKEN_LOCAL_STORAGE_KEY(CoveoSearchHub.LISTING)
  );
};
