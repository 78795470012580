import { useCurrency } from '@contexts/CurrencyContext';
import { useRouter } from '@contexts/Router/RouterContext';
import {
  Context,
  SearchEngine,
  SearchEngineConfiguration,
  buildContext,
  buildDictionaryFieldContext,
  buildSearchEngine,
  getOrganizationEndpoints,
  loadPaginationActions,
} from '@coveo/atomic-react';
import { analyticsClientMiddleware } from '@lib/coveo/analyticsClientMiddleware';
import { CoveoSearchHub } from '@lib/coveo/coveoConstants';
import { interceptSearchMiddleware } from '@lib/coveo/interceptSearchMiddleware';
import {
  getNewSearchToken,
  getSavedSearchToken,
} from '@lib/coveo/searchTokenHelpers';
import { useCookieSettings } from '@lib/hooks/useCookieSettings';
import { useIsClient } from '@lib/hooks/useIsClient';
import { useEffect, useMemo, useState } from 'react';
import { DEFAULT_LOCALE } from 'utils';

function getSearchEngineConfiguration(
  searchHub: CoveoSearchHub,
  engineUniqueId: string,
  searchToken: string,
  analyticsEnabled: boolean,
  locale?: string
): SearchEngineConfiguration {
  const organizationId = process.env.NEXT_PUBLIC_COVEO_ORGANIZATION_ID!;

  return {
    accessToken: searchToken,
    organizationId,
    organizationEndpoints: getOrganizationEndpoints(organizationId),
    renewAccessToken: () => getNewSearchToken(searchHub),
    name: engineUniqueId,
    search: {
      searchHub,
      locale: locale ?? DEFAULT_LOCALE,
    },
    analytics: {
      enabled: analyticsEnabled,
      analyticsClientMiddleware,
    },
  };
}

export const useCoveoHeadlessEngine = (
  searchHub: CoveoSearchHub,
  engineUniqueId: string,
  pageSize?: number
) => {
  const isClient = useIsClient();
  const { locale } = useRouter();
  const [searchToken, setSearchToken] = useState<string | null>(null);
  const [searchEngine, setSearchEngine] = useState<SearchEngine | undefined>();
  const [coveoContext, setCoveoContext] = useState<Context | undefined>();
  const { currency } = useCurrency();
  const { functionalCategoryEnabled } = useCookieSettings();

  useEffect(() => {
    if (isClient) {
      const savedSearchToken = getSavedSearchToken(searchHub);

      if (!savedSearchToken || savedSearchToken === '{}') {
        getNewSearchToken(searchHub).then((token) => {
          setSearchToken(token);
        });
      } else {
        setSearchToken(JSON.parse(savedSearchToken));
      }
    }
  }, [isClient, searchHub]);

  useEffect(() => {
    // coveo atomic can't be rendered on the server side since components are initialized on the client side
    // trying to render it on server results in different content being rendered on server + first client render, which throws a Next Hydration error
    if (isClient && searchToken && !searchEngine) {
      setSearchEngine(
        buildSearchEngine({
          configuration: getSearchEngineConfiguration(
            searchHub,
            engineUniqueId,
            searchToken,
            functionalCategoryEnabled,
            locale
          ),
          middlewares: [interceptSearchMiddleware],
        })
      );
    }
  }, [
    isClient,
    searchToken,
    searchEngine,
    locale,
    functionalCategoryEnabled,
    searchHub,
    engineUniqueId,
  ]);

  useEffect(() => {
    if (searchEngine) {
      const dictionaryContext = buildDictionaryFieldContext(searchEngine);
      dictionaryContext.set({ ec_price: currency, ec_price_filter: currency });
    }
  }, [searchEngine, currency]);

  useEffect(() => {
    if (searchEngine && !coveoContext) {
      setCoveoContext(buildContext(searchEngine));
    }
  }, [searchEngine, coveoContext]);

  useEffect(() => {
    if (coveoContext) {
      // important to use .add here instead of .set, as we want to add to the existing context if it exists, not replace it
      coveoContext.add('website', 'assetstore');
      coveoContext.add('language', locale);
      // unique id defined by us to differentiate between different usages of the engine
      // ie: lets us track with coveo analytics performance of different listing blocks instead of them all just being under "Listing" query engine
      coveoContext.add('engineUniqueId', engineUniqueId);
    }
  }, [coveoContext, locale, engineUniqueId]);

  useEffect(() => {
    if (searchEngine && pageSize) {
      // Manually dispatch an action to set the number of results per page with the search engine
      // Have this option for the listing blocks, as we don't have a Results Per Page controller on those.
      // search has a Results per page, and that controller handles setting the number of results
      const action =
        loadPaginationActions(searchEngine).registerNumberOfResults(pageSize);
      searchEngine.dispatch(action);
    }
  }, [searchEngine, pageSize]);

  useEffect(() => {
    if (searchEngine) {
      if (functionalCategoryEnabled) {
        searchEngine.enableAnalytics();
      } else {
        searchEngine.disableAnalytics();
      }
    }
  }, [searchEngine, functionalCategoryEnabled]);

  return useMemo(
    () => ({ searchEngine, coveoContext }),
    [searchEngine, coveoContext]
  );
};
